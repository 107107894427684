.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Add this CSS in your stylesheet */
.sticky1 {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Ensure it stays above other content */
  background-color: #313131;
  /* Optional: Add a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for better visibility */
}

.border-gap {
  position: relative;
}

.border-gap::before,
.border-gap::after {
  content: '';
  position: absolute;
  border-color: #313131;
}

.border-gap::before {
  top: 0;
  right: 0;
  border-top-width: 1px;
  border-right-width: 1px;
  width: 5px;
  height: 5px;
}

.border-gap::after {
  bottom: 0;
  right: 0;
  border-bottom-width: 5px;
  border-right-width: 5px;
  width: 5px;
  height: 5px;
}

.navbar-container {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 0;

  z-index: 1000;
  background-color: #F0ECE8;
  /* Optional: Add a background color */
  transition: padding 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-container1 {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 0;

  z-index: 1000;
  background-color: #313131;
  /* Optional: Add a background color */
  transition: padding 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.bck {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for better visibility */

}



.App-link {
  color: #61dafb;
}

.fontClass {
  font-family: "Roboto", sans-serif;
}

.latClass {
  font-family: "Lato", sans-serif;
}

/* styles.css */
.gradient-text {
  background-color: #f0ece8;

  background-image: linear-gradient(0deg,
      rgba(49, 49, 49, 0) 1.62%,
      #313131 99.93%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  direction: rtl;
}

.gradient-text1 {
  background-color: #f0ece8;

  background-image: linear-gradient(180deg,
      rgba(49, 49, 49, 0) 1.62%,
      #313131 99.93%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  direction: rtl;
}

@keyframes backInRight {
  0% {
    -webkit-transform: translateX(100px) scale(0.7);
    transform: translateX(100px) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-100px) scale(0.7);
    transform: translateX(-100px) scale(0.7);
    opacity: 0.7;
  }
}

@keyframes upAndDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
    /* Adjust this value to control the vertical movement */
  }
}

.animated-image {
  animation: upAndDown 1s infinite;
  /* Adjust the duration (1s) as needed */
}

.color-1 {
  color: red;
  /* Example color */
}

.color-2 {
  color: blue;
  /* Example color */
}

.color-3 {
  color: green;
  /* Example color */
}

/* Add more color classes as needed */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}